import React from "react"
import Obfuscate from 'react-obfuscate';

import Layout from "../components/layout"
import SEO from "../components/seo"

const LegalPage = () => (
  <Layout>
    <SEO title="Mentions légales" />
    <section className="hero pattern-zigzag-light" style={{marginTop: -4}}>
        <div className="hero-body">
          <div className="container has-text-centered">
            <h1 className="title dreamland is-size-2">
              Mentions legales
            </h1>
          </div>
        </div>
      </section>
      <section>
        <div className="container has-text-centered" style={{padding: "0 1rem"}}>
          <h2 className="is-size-3 almond mt-5 mb-5">Responsable de la publication</h2>
          <p>Serge Grass, président de l'association Agro Bio Conso</p>
          <p className="mt-3">77 grande-Rue</p>
          <p>25360 Nancray</p>
          <p className="mt-3"><Obfuscate email="sg44@free.fr" /></p>
          <h2 className="is-size-3 almond mt-5 mb-5">Hébergement</h2>
          <p>Le serveur est hébergé en France par :</p>
          <p className="mt-3 has-text-weight-bold">OVH</p>
          <p>2 rue Kellermann</p>
          <p>59100 Roubaix</p>
          <p>FRANCE</p>
          <p><a href="https://www.ovh.com">www.ovh.com</a></p>
        </div>
      </section>
  </Layout>
)

export default LegalPage
